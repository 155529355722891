<script>
    import { _ } from 'svelte-i18n'
    import { getConsent, deleteCookies, deleteLogin } from '$utils/api.js'
    import { global } from '$src/state.svelte.js'
    import { push as navigateTo } from 'svelte-spa-router'

    const showLogoutBtn = $derived(
        (global.data?.isPersonalLoggedIn || global.data?.isManagedLoggedIn) &&
            !global.isRemoteAuthClient,
    )
    const showForgetMeBtn = $derived(
        global.data?.preferred?.length ||
            global.data?.recovery?.length ||
            global.data?.managed?.length ||
            global.data?.login_hint,
    )

    let logoutAjax = $state(false)
    async function logout() {
        logoutAjax = true
        global.spinner = true
        await deleteLogin({ clearSession: false })
        global.data = await getConsent(sessionStorage.authorize_query_params)
        global.spinner = false
        logoutAjax = false
        navigateTo('/login')
    }

    let forgetMeAjax = $state(false)
    async function forgetMeHandler() {
        forgetMeAjax = true
        global.spinner = true

        // forget login_hint and domain_hint
        const params = new URLSearchParams(
            sessionStorage.authorize_query_params,
        )
        params.delete('login_hint')
        params.delete('domain_hint')
        sessionStorage.setItem('authorize_query_params', params.toString())

        await deleteCookies()
        global.data = await getConsent(sessionStorage.authorize_query_params)
        global.spinner = false
        forgetMeAjax = false
        navigateTo('/login')
    }
</script>

<header
    data-test="authorize-title-bar"
    class="flex justify-between border-b border-[#c8c8c8] px-1 pt-1 dark:border-[#656565]"
>
    <div>{@html $_('hello_container.hello_btn')}</div>
    {#if !global.spinner}
        {#if showLogoutBtn}
            <button
                data-test="logout-btn"
                onclick={logout}
                disabled={logoutAjax}
                class="text-sm opacity-80 hover:underline focus:underline"
            >
                {logoutAjax ? 'Logging out...' : $_('Log out')}
            </button>
        {:else if showForgetMeBtn}
            <button
                data-test="forget-me-btn"
                onclick={forgetMeHandler}
                disabled={forgetMeAjax}
                class="text-sm opacity-80 hover:underline focus:underline"
            >
                {forgetMeAjax ? $_('Forgetting') : $_('Forget me')}
            </button>
        {/if}
    {/if}
</header>
