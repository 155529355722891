<script>
    import { onMount } from 'svelte'
    import Router from 'svelte-spa-router'
    import routes from './authorize/index.js'
    import { global } from '$src/state.svelte.js'
    import { notification, sessionCountdown } from '$src/stores.js'
    import { handleUnrecoverableError } from '$utils/errors.js'
    import {
        SESSION_EXPIRE_SHOW_MODAL_MS,
        SESSION_MODAL_WARNING_MS,
    } from '$src/constants.js'
    import { deleteLogin } from '$utils/api.js'
    import { formatTime } from '$utils/common.js'
    import { _, locale, isLoading } from 'svelte-i18n'
    import MergeModal from '$lib/modal/MergeModal.svelte'
    import UpgradePreferredModal from '$lib/modal/UpgradePreferredModal.svelte'
    import AuthorizeErrorModal from '$lib/modal/error/AuthorizeErrorModal.svelte'
    import AuthorizeSessionExpiredModal from '$lib/modal/AuthorizeSessionExpiredModal.svelte'

    let showSessionExpiredModal = $state(false)

    onMount(() => {
        //Remove load spinner in HTML page
        document.getElementById('load-spinner')?.remove()
        //Show Svelte spinner
        global.spinner = true
    })

    $effect(() => {
        // if (!IS_PROD) console.log('[debug] session:', $sessionCountdown);
        if (!$locale || $isLoading) return

        if (
            $sessionCountdown < 1 &&
            !global.serverErrorModal && // server errors also kills countdown
            !showSessionExpiredModal
        ) {
            deleteLogin().finally(() => {
                // this also clears sessionStorage authz query params
                notification.clear() //we show modal
                showSessionExpiredModal = true
                sessionCountdown.clear()
            })
        } else if (
            $sessionCountdown !== 0 && //not expired
            $sessionCountdown <= SESSION_EXPIRE_SHOW_MODAL_MS / 1000
        ) {
            notification.show(
                $_('Session expires in {time}', {
                    values: { time: formatTime($sessionCountdown) },
                }),
                $sessionCountdown <= SESSION_MODAL_WARNING_MS / 1000
                    ? 'error'
                    : 'warn',
                false,
            )
        }

        if ($locale.startsWith('ar')) {
            document.dir = 'rtl'
        } else {
            document.dir = 'ltr'
        }
    })
</script>

<svelte:window
    onerror={handleUnrecoverableError}
    onunhandledrejection={handleUnrecoverableError}
/>

{#if !$locale || $isLoading || global.spinner}
    <div class="spinner"></div>
{/if}

{#if $locale && !$isLoading}
    {#if global.serverErrorModal || global.clientErrorModal}
        <AuthorizeErrorModal />
    {/if}

    {#if global.mergeAccounts}
        <MergeModal />
    {/if}

    {#if global.upgradePreferred}
        <UpgradePreferredModal />
    {/if}

    {#if showSessionExpiredModal}
        <AuthorizeSessionExpiredModal />
    {/if}

    <div class="px-outer mx-auto flex h-full max-w-[856px] flex-col">
        <Router {routes} />
    </div>
{/if}
