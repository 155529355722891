<script>
    import { onDestroy, onMount } from 'svelte'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import { handleConsentResponse } from '$utils/consent.js'
    import { deleteLoginQRCode } from '$utils/api.js'
    import { IS_PROD } from '$src/constants.js'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import Notification from '$lib/Notification.svelte'

    let authorizationCancelled = $state(false)
    let remoteAuthDone = $state(false)
    let evtSource
    let app = $state({})

    onMount(async () => {
        global.spinner = true

        notification.show('QR Code was scanned', 'success')

        evtSource = new EventSource('/api/v1/login/qrcode/remote')
        evtSource.addEventListener('response', (event) => {
            remoteAuthDone = true
            evtSource.close()
            const json = JSON.parse(event.data)
            if (
                json?.params?.error &&
                json?.params?.error === 'access_denied'
            ) {
                notification.show(
                    'Authorization was cancelled<br/>on the other device',
                    'error',
                )
            } else {
                notification.show('Authorization is complete', 'success')
            }
            setTimeout(() => {
                handleConsentResponse(json)
                return
            }, 500)
        })

        evtSource.addEventListener('keep-alive', (event) => {
            if (!IS_PROD) {
                console.log('keep-alive: ' + event.data)
            }
        })

        try {
            app = JSON.parse(sessionStorage.app)
        } catch (err) {
            console.error(err)
        }

        logPlausibleEvent({ u: '/remote' })

        global.spinner = false
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
        notification.clear()
    })

    async function cancelConsent() {
        global.spinner = true
        sessionStorage.removeItem('az_release_funnel')
        await deleteLoginQRCode()
        evtSource.close()
        notification.show('Authorization was cancelled', 'error')
        authorizationCancelled = true
        const response = {
            uri: app.redirect_uri,
            response_mode: app.response_mode,
            params: {
                error: 'access_denied',
            },
        }
        setTimeout(() => {
            handleConsentResponse(response)
            return
        }, 500)
    }
</script>

{#if !global.spinner}
    {#if $notification.text}
        <Notification css="mt-2 rounded-md" />
    {/if}

    <main class="flex-1 overflow-y-auto px-3 pb-16 md:px-0">
        <div class="mt-8">
            <div class="mx-auto max-w-2xl px-3 text-center">
                {#if remoteAuthDone || authorizationCancelled}
                    <SpinnerIcon
                        css="h-8 w-8 text-charcoal dark:text-[#d4d4d4] mx-auto mb-8"
                    />
                    <h2 class="text-xl">Redirecting you to<br />{app.name}</h2>
                {:else if !remoteAuthDone && !authorizationCancelled}
                    <h1 class="text-center text-xl">
                        Waiting for remote<br />authorization
                    </h1>
                    <SpinnerIcon
                        css="h-8 w-8 text-charcoal dark:text-[#d4d4d4] mx-auto my-8"
                    />
                    <button
                        class="mx-auto block hover:underline focus:underline"
                        onclick={cancelConsent}>Cancel</button
                    >
                {/if}
            </div>
        </div>
    </main>
{/if}
