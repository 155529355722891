<script>
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { _ } from 'svelte-i18n'
    import { push as navigateTo } from 'svelte-spa-router'
    import { logPlausibleEvent } from '$utils/plausible'
    import { generateQR } from '$utils/qr'
    import RecommendedProviders from '$lib/login/RecommendedProviders.svelte'
    import {
        getConsent,
        postLoginEth,
        postLoginEthChallenge,
        postLoginProvider,
        deleteLoginQRCode,
    } from '$utils/api.js'
    import { push } from 'svelte-spa-router'
    import { trimEthAddress, getWallet } from '$utils/ethereum.js'
    import { handleConsentResponse } from '$utils/consent.js'
    import { IS_PROD, AUTHORIZE_FUNNEL_STEPS } from '$src/constants.js'
    import { deviceTheme } from '$utils/device.js'
    import LoginPreferred from './login/LoginPreferred.svelte'
    import LoginManaged from './login/LoginManaged.svelte'
    import RecoveryStatus from './login/RecoveryStatus.svelte'
    import ManagedProvider from './ManagedProvider.svelte'
    import QRModal from './modal/QRModal.svelte'
    import UsePersonalAccount from './login/UsePersonalAccount.svelte'
    import LoginHint from './login/LoginHint.svelte'
    import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte'

    let {
        showQRLogin = false,
        qrcode = () => {},
        recommendedProviders = [],
        handleRemoteAuthSuccess = () => {},
    } = $props()

    let ethereumProgressModal = $state()
    let ethereumProgressNotifs = $state([])
    let usePersonalAccount = $state(false)
    let evtSource
    let qrLink = $state('')
    let qrSvg = $state(null)
    let showQRModal = $state(false)
    let showRecoveries = $state(false)

    const recoveryIds = $derived(global.data?.recovery?.map((i) => i.id) || [])
    const recoverable = $derived(recoveryIds.length >= 2)
    const loggedInSubject = $derived(
        global.data?.recovery?.find(
            (i) => i.id === global.data?.subjects?.[0],
        ) || {},
    )
    const isRecoveryMode = $derived(
        !global.data?.isPersonalLoggedIn &&
            !!global.data?.subjects?.find((subject) =>
                recoveryIds.includes(subject),
            ),
    )
    const isManagedRecoveryMode = $derived(
        !global.data?.isPersonalLoggedIn && global.data?.isManagedLoggedIn,
    )
    const hasPreferred = $derived(!!global.data?.preferred?.length)
    const hasManaged = $derived(!!global.data?.managed?.length)
    const isPersonalLoggedIn = $derived(global.data?.isPersonalLoggedIn)
    const isManagedLoggedIn = $derived(global.data?.isManagedLoggedIn)
    const promptLogin = $derived(global.data?.promptLogin)
    const showManaged = $derived(global.data?.accountToUse !== 'personal')
    const isPersonalView = $derived(global.data?.accountToUse === 'personal')
    const isManagedView = $derived(global.data?.accountToUse === 'managed')
    const authnWithPreferred = $derived(hasPreferred && !isPersonalLoggedIn)
    const authnWithManaged = $derived(
        !hasPreferred && hasManaged && !isManagedLoggedIn,
    )
    const loginHint = $derived(global.data?.login_hint)
    const requiresPersonal = $derived(global.data?.release?.requiresPersonal)

    const dropdown = $state({
        showAll: false,
        email: false,
        phone: false,
    })

    async function contactLogin({ email } = {}) {
        const res = await getConsent()
        global.data = res

        //AZ Funnel
        const client_id =
            new URLSearchParams(sessionStorage.authorize_query_params)?.get(
                'client_id',
            ) || global?.data?.app?.client_id
        const redirect_uri =
            new URLSearchParams(sessionStorage.authorize_query_params)?.get(
                'redirect_uri',
            ) || global?.data?.app?.redirect_uri
        let redirect
        try {
            redirect = new URL(redirect_uri)?.hostname
        } catch (err) {
            console.error(err)
        }

        //New User Release Funnel
        const indexOfCurrentFunnelStep = AUTHORIZE_FUNNEL_STEPS.indexOf(
            sessionStorage.az_release_funnel,
        )
        const indexOfNextFunnelStep =
            AUTHORIZE_FUNNEL_STEPS.indexOf('az_login_success')
        //session funnel state is valid and not already sent
        if (
            indexOfCurrentFunnelStep !== -1 &&
            indexOfNextFunnelStep > indexOfCurrentFunnelStep
        ) {
            //existing user: not auto-flow + isNewUser + not rerelease
            if (
                !global.data?.uri &&
                !global.data?.response_mode &&
                global.data?.isNewUser &&
                !global.data?.release?.previous
            ) {
                await logPlausibleEvent({
                    n: 'AZ Login Success',
                    p: {
                        client_id,
                        provider: email ? 'email' : 'phone',
                        redirect,
                    },
                    u: '/login',
                })
                sessionStorage.setItem('az_release_funnel', 'az_login_success')
            } else {
                await logPlausibleEvent({
                    n: 'AZ Existing User',
                    p: {
                        client_id,
                        provider: email ? 'email' : 'phone',
                        redirect,
                    },
                    u: '/login',
                })
                sessionStorage.removeItem('az_release_funnel')
            }
        }

        if (res.uri && res.response_mode) {
            global.spinner = true
            handleConsentResponse(res)
            return res
        }
    }

    async function contactLoginSuccess(e) {
        global.spinner = true
        const res = await contactLogin(e)
        if (!res?.uri && !res?.response_mode) {
            push('/')
        }
    }

    function handleContactError(err) {
        if (err.status !== 404) {
            dropdown.showAll = dropdown.phone = dropdown.email = false
        }
    }

    async function continueWithEthereumExtension({
        info,
        account,
        accountSelected,
        accountToUse,
        useDifferentManaged,
    } = {}) {
        const [address] = await window.ethereum.request({
            method: 'eth_requestAccounts',
        })
        ethereumProgressModal = 'extension'
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                text: $_('Wallet Connected ({address})', {
                    values: {
                        address: trimEthAddress(address),
                    },
                }),
                type: 'success',
                status: $_('Waiting to sign'),
            },
        ]
        if (
            info?.login_hint &&
            info?.login_hint.toLowerCase() !== address.toLowerCase()
        ) {
            ethereumProgressNotifs = [
                ...ethereumProgressNotifs,
                {
                    text: $_('Expected ({address})', {
                        values: {
                            address: trimEthAddress(info.login_hint),
                        },
                    }),
                    type: 'error',
                    status: address,
                },
            ]
            return
        }
        continueEthExtensionSigning(
            address,
            account,
            accountSelected,
            accountToUse,
            useDifferentManaged,
        )
    }

    async function continueEthExtensionSigning(
        address,
        account,
        accountSelected,
        accountToUse,
        useDifferentManaged,
    ) {
        let challenge, signature
        const slug = getWallet().slug
        const res = await postLoginEth(address)
        logPlausibleEvent({
            u: `/start/login/ethereum/extension/${slug}`,
            n: 'action',
        })
        challenge = res.challenge
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                status: $_('Waiting to sign'),
            },
        ]

        //New User Release Funnel
        const indexOfCurrentFunnelStepStart = AUTHORIZE_FUNNEL_STEPS.indexOf(
            sessionStorage.az_release_funnel,
        )
        const indexOfNextFunnelStepStart =
            AUTHORIZE_FUNNEL_STEPS.indexOf('az_login_start')
        //session funnel state is valid and not already sent + is authorize app
        if (
            indexOfCurrentFunnelStepStart !== -1 &&
            indexOfNextFunnelStepStart > indexOfCurrentFunnelStepStart
        ) {
            const client_id =
                new URLSearchParams(sessionStorage.authorize_query_params)?.get(
                    'client_id',
                ) || global?.data?.app?.client_id
            const isRecommendedProvider = recommendedProviders.includes(slug)
            const redirect_uri =
                new URLSearchParams(sessionStorage.authorize_query_params)?.get(
                    'redirect_uri',
                ) || global?.data?.app?.redirect_uri
            let redirect
            try {
                redirect = new URL(redirect_uri)?.hostname
            } catch (err) {
                console.error(err)
            }
            logPlausibleEvent({
                n: 'AZ Login Start',
                p: {
                    client_id,
                    provider: slug,
                    recommended_provider: isRecommendedProvider,
                    redirect,
                },
                u: '/login',
            })
            sessionStorage.setItem('az_release_funnel', 'az_login_start')
        }

        try {
            signature = await window.ethereum.request({
                method: 'personal_sign',
                params: [address, challenge],
            })
            ethereumProgressNotifs = [
                ...ethereumProgressNotifs,
                {
                    text: $_('Message signed'),
                    type: 'success',
                    status: $_('Logging you in'),
                },
            ]
        } catch (err) {
            console.info(err)
            if (err.code === 4001) {
                notification.show(
                    $_("You've rejected the sign request"),
                    'error',
                )
            } else {
                notification.show(
                    $_('Something went wrong. Please try again later.'),
                    'error',
                )
            }
            ethereumProgressModal = null
            ethereumProgressNotifs = []
            return
        }

        const body = {
            signature,
            address,
            icon: getWallet().icon,
            name: getWallet().name,
            account,
            accountSelected,
            accountToUse,
            useDifferentManaged,
        }

        await postLoginEthChallenge(body)
        await logPlausibleEvent({
            u: `/login/ethereum/extension/${getWallet().slug}`,
            n: 'action',
        })
        global.data = await getConsent()

        //New User Release Funnel

        const indexOfCurrentFunnelStepEnd = AUTHORIZE_FUNNEL_STEPS.indexOf(
            sessionStorage.az_release_funnel,
        )
        const indexOfNextFunnelStepEnd =
            AUTHORIZE_FUNNEL_STEPS.indexOf('az_login_success')
        //session funnel state is valid and not already sent + is authorize app
        if (
            indexOfCurrentFunnelStepEnd !== -1 &&
            indexOfNextFunnelStepEnd > indexOfCurrentFunnelStepEnd
        ) {
            const client_id =
                new URLSearchParams(sessionStorage.authorize_query_params)?.get(
                    'client_id',
                ) || global?.data?.app?.client_id
            const redirect_uri =
                new URLSearchParams(sessionStorage.authorize_query_params)?.get(
                    'redirect_uri',
                ) || global?.data?.app?.redirect_uri
            let redirect
            try {
                redirect = new URL(redirect_uri)?.hostname
            } catch (err) {
                console.error(err)
            }
            if (
                !global.data?.uri &&
                !global.data?.response_mode &&
                global.data?.isNewUser &&
                !global.data?.release?.previous
            ) {
                //existing user: not auto-flow + isNewUser + not rerelease
                await logPlausibleEvent({
                    n: 'AZ Login Success',
                    p: { client_id, provider: slug, redirect },
                    u: '/login',
                })
                sessionStorage.setItem('az_release_funnel', 'az_login_success')
            } else {
                await logPlausibleEvent({
                    n: 'AZ Existing User',
                    p: { client_id, provider: slug, redirect },
                    u: '/login',
                })
                sessionStorage.removeItem('az_release_funnel')
            }
        }

        if (!res?.uri && !res?.response_mode) {
            push('/')
        } else {
            global.spinner = true
            handleConsentResponse(res)
        }
    }

    async function skipLoginHandler() {
        global.spinner = true
        const params = new URLSearchParams()
        params.set('accountSelected', 'personal')
        global.data = await getConsent(params.toString())
        if (global.data.error) {
            //we handle the error in release page
            window.history.replaceState(
                {},
                document.title,
                window.location.pathname,
            )
            return window.location.reload()
        }

        if (global.data?.uri && global.data?.response_mode)
            return handleConsentResponse(global.data) //go back to app with response

        if (global.data?.success)
            // success at remote auth client
            return handleRemoteAuthSuccess()

        return push('/')
    }

    async function continueWithProvider({ slug, body, server } = {}) {
        global.spinner = true
        const { redirect } = await postLoginProvider({
            slug,
            body,
            server,
        })

        //New User Release Funnel
        const indexOfCurrentFunnelStep = AUTHORIZE_FUNNEL_STEPS.indexOf(
            sessionStorage.az_release_funnel,
        )
        const indexOfNextFunnelStep =
            AUTHORIZE_FUNNEL_STEPS.indexOf('az_login_start')
        //session funnel state is valid and not already sent + is authorize app
        if (
            indexOfCurrentFunnelStep !== -1 &&
            indexOfNextFunnelStep > indexOfCurrentFunnelStep
        ) {
            const client_id =
                new URLSearchParams(sessionStorage.authorize_query_params)?.get(
                    'client_id',
                ) || global?.data?.app?.client_id
            const isRecommendedProvider = recommendedProviders.includes(slug)
            const redirect_uri =
                new URLSearchParams(sessionStorage.authorize_query_params)?.get(
                    'redirect_uri',
                ) || global?.data?.app?.redirect_uri
            let redirect
            try {
                redirect = new URL(redirect_uri)?.hostname
            } catch (err) {
                console.error(err)
            }
            await logPlausibleEvent({
                n: 'AZ Login Start',
                p: {
                    client_id,
                    provider: slug,
                    recommended_provider: isRecommendedProvider,
                    redirect,
                },
                u: '/login',
            })
            sessionStorage.setItem('az_release_funnel', 'az_login_start')
        }
        window.location.href = redirect
    }

    async function handleManagedEmailSuccess() {
        global.data = await getConsent()
        return push('/')
    }

    let continueWithQRCodeAjax = $state(false)
    function continueWithQRCode() {
        continueWithQRCodeAjax = true
        const searchParams = new URLSearchParams()
        searchParams.set('prefers-color-scheme', deviceTheme)
        searchParams.set('language', window.navigator.language)

        const authorizeQueryParams = sessionStorage.getItem(
            'authorize_query_params',
        )
        const originalSearchParams = new URLSearchParams(authorizeQueryParams)
        const providerHintsStr =
            originalSearchParams.get('provider_hint') ||
            originalSearchParams.get('providerHints') ||
            global.data.provider_hint ||
            global.data.providerHints
        if (providerHintsStr) {
            const filteredProviderHints = providerHintsStr
                //does not make sense to propagate the qrcode hint to remote auth client where its going to be hidden anyway
                .replaceAll('qrcode', '')
                .replaceAll('  ', ' ')
                .trim()
            if (filteredProviderHints) {
                searchParams.set('provider_hint', filteredProviderHints)
            }
        }

        evtSource = new EventSource(
            '/api/v1/login/qrcode?' + searchParams.toString(),
        )
        evtSource.addEventListener('qr_code', async ({ data }) => {
            qrLink = data
            qrSvg = await generateQR(data)
            continueWithQRCodeAjax = false
            showQRModal = true
        })
        evtSource.addEventListener('scanned', () => {
            evtSource.close()
            navigateTo('/remote')
        })
        evtSource.addEventListener('keep-alive', (event) => {
            if (!IS_PROD) {
                console.log('keep-alive: ' + event.data)
            }
        })
    }

    async function deleteQRCode() {
        showQRModal = false
        qrLink = ''
        await deleteLoginQRCode()
        if (evtSource) evtSource.close()
    }
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
    <EthereumProgressModal
        notifications={ethereumProgressNotifs}
        cancel={() => {
            ethereumProgressNotifs = []
            ethereumProgressModal = null
        }}
        ok={(e) => {
            if (ethereumProgressModal === 'extension') {
                continueEthExtensionSigning(e)
            }
        }}
    />
{/if}

{#if showQRModal}
    <QRModal {deleteQRCode} {qrSvg} {qrLink} />
{/if}

{#if requiresPersonal}
    <h1
        class="relative mb-6 flex items-center justify-center text-lg font-medium"
    >
        {$_('Requires a personal account')}
    </h1>

    {#if hasPreferred}
        <LoginPreferred
            accountSelected="managed"
            accounts={global.data.preferred}
            {contactLoginSuccess}
            {handleContactError}
            {continueWithEthereumExtension}
            {continueWithProvider}
            skipLogin={isPersonalLoggedIn && !promptLogin}
            {skipLoginHandler}
        />
    {:else}
        <RecommendedProviders
            accountSelected="managed"
            {recommendedProviders}
            {continueWithQRCodeAjax}
            showManagedLogin={false}
            {contactLoginSuccess}
            {handleContactError}
            {continueWithEthereumExtension}
            {continueWithProvider}
            {showQRLogin}
            {qrcode}
        />
    {/if}
{:else if loginHint}
    <LoginHint
        login_hint={loginHint}
        {continueWithProvider}
        {contactLoginSuccess}
        {handleContactError}
        {handleManagedEmailSuccess}
        {continueWithEthereumExtension}
    />
{:else if isPersonalView}
    {#if isRecoveryMode}
        <RecoveryStatus
            accountSelected="personal"
            {continueWithProvider}
            {contactLoginSuccess}
            {handleContactError}
            {continueWithEthereumExtension}
            {loggedInSubject}
        />
    {/if}

    {#if hasManaged && !hasPreferred && !isPersonalLoggedIn && !isManagedLoggedIn}
        <LoginManaged
            heading={$_('Requires a personal account')}
            label={$_('To set one up:<br/>Log in with your managed account')}
            accountToUse="personal"
            accounts={global.data.managed}
            {continueWithProvider}
            {authnWithPreferred}
            {authnWithManaged}
            {contactLoginSuccess}
            {handleContactError}
            {continueWithEthereumExtension}
        />
    {:else if !isRecoveryMode || (isManagedRecoveryMode && !recoverable)}
        {#if hasPreferred}
            <LoginPreferred
                accountSelected="personal"
                label={$_('Use your preferred login provider')}
                accounts={global.data.preferred}
                {contactLoginSuccess}
                {handleContactError}
                {continueWithEthereumExtension}
                {continueWithProvider}
                skipLogin={isPersonalLoggedIn && !promptLogin}
                {skipLoginHandler}
            />
        {:else}
            <RecommendedProviders
                accountSelected="personal"
                label={$_('Select your preferred login provider')}
                {recommendedProviders}
                {continueWithQRCodeAjax}
                showManagedLogin={showManaged}
                {contactLoginSuccess}
                {handleContactError}
                {continueWithEthereumExtension}
                {continueWithProvider}
                {handleManagedEmailSuccess}
                {showQRLogin}
                {qrcode}
            />
        {/if}
    {/if}
{:else if isManagedView}
    {#if hasPreferred && !isPersonalLoggedIn && !hasManaged}
        <LoginPreferred
            accountToUse="managed"
            heading={$_('Requires a managed account')}
            label={$_('To set one up:<br/>Log in with your personal account')}
            accounts={global.data.preferred}
            {contactLoginSuccess}
            {handleContactError}
            {continueWithEthereumExtension}
            {continueWithProvider}
            skipLogin={isPersonalLoggedIn && !promptLogin}
            {skipLoginHandler}
        />
    {:else if hasManaged}
        <LoginManaged
            accountSelected="managed"
            accountToUse="managed"
            {authnWithPreferred}
            {authnWithManaged}
            accounts={global.data.managed}
            {continueWithProvider}
            {contactLoginSuccess}
            {handleContactError}
            {continueWithEthereumExtension}
            {handleManagedEmailSuccess}
        />
    {:else}
        <ManagedProvider
            login={true}
            accountSelected="managed"
            managedEmailSuccess={handleManagedEmailSuccess}
        />
    {/if}
{:else if hasPreferred || hasManaged}
    <!-- Shows available account at top -->
    <div class="flex flex-col gap-y-6" class:flex-col-reverse={!hasPreferred}>
        {#if !global.data.preferred?.length}
            <div>
                <UsePersonalAccount
                    bind:usePersonalAccount
                    {authnWithManaged}
                    {contactLoginSuccess}
                    {handleContactError}
                    {continueWithEthereumExtension}
                    {continueWithProvider}
                />

                {#if usePersonalAccount}
                    <RecommendedProviders
                        accountSelected="personal"
                        {recommendedProviders}
                        {continueWithQRCodeAjax}
                        {showQRLogin}
                        showManagedLogin={false}
                        {contactLoginSuccess}
                        {handleContactError}
                        {continueWithEthereumExtension}
                        {continueWithProvider}
                        {handleManagedEmailSuccess}
                        {qrcode}
                    />
                {/if}
            </div>
        {:else}
            <LoginPreferred
                accountSelected="personal"
                label={hasPreferred ? $_('Use your personal account') : ''}
                accounts={global.data.preferred}
                {contactLoginSuccess}
                {handleContactError}
                {continueWithEthereumExtension}
                {continueWithProvider}
                bind:showRecoveries
                skipLogin={isPersonalLoggedIn && !promptLogin}
                {skipLoginHandler}
            />
        {/if}

        <!-- 
		hide managed accounts when recovery options are expanded
		so that 2 managed buttons are not confusing, one being recovery and other being managed)
		-->
        {#if !showRecoveries}
            <LoginManaged
                label={hasManaged ? $_('Use your managed account') : ''}
                accountSelected="managed"
                {authnWithPreferred}
                {authnWithManaged}
                accounts={global.data.managed}
                {continueWithProvider}
                {contactLoginSuccess}
                {handleContactError}
                {continueWithEthereumExtension}
            />
        {/if}
    </div>
{:else}
    <RecommendedProviders
        label={$_('Select your preferred login provider')}
        {recommendedProviders}
        showManagedLogin={showManaged}
        accountSelected="unknown"
        {continueWithQRCodeAjax}
        {contactLoginSuccess}
        {handleContactError}
        {continueWithEthereumExtension}
        {continueWithProvider}
        {handleManagedEmailSuccess}
        qrcode={continueWithQRCode}
        {showQRLogin}
    />
{/if}
