<script>
    import { _ } from 'svelte-i18n'
    import { global } from '$src/state.svelte.js'
    import { returnErrorToApp } from '$utils/consent.js'
    import FullPageModal from '$lib/modal/FullPageModal.svelte'

    const appName = $derived(global?.data?.app?.name)
</script>

<FullPageModal modalCss="sm:max-w-sm sm:!-mt-80">
    <div class="mx-auto w-full text-center">
        <h1 class="text-lg font-medium text-red-500">
            {$_('Session expired')}
        </h1>
        {#if appName}
            <button
                onclick={() =>
                    returnErrorToApp({
                        error: 'access_denied',
                        error_description: 'The session expired',
                    })}
                class="btn-background mt-5 inline-flex !h-auto w-full items-center justify-center space-x-2 py-2"
            >
                Return to {appName}<br />and start over
            </button>
        {:else}
            <button
                onclick={() =>
                    (window.location.href = window.location.origin + '/')}
                class="btn-background mt-5 inline-flex w-full items-center justify-center space-x-2"
            >
                <span>Return to Wallet Profile</span>
            </button>
        {/if}
    </div>
</FullPageModal>
