<script>
    import { _ } from 'svelte-i18n'
    import { slide } from 'svelte/transition'
    import { global } from '$src/state.svelte.js'
    import { dedupe } from '$utils/common'
    import { getConsent } from '$utils/api.js'
    import { SLIDE_ANIM_MS } from '$src/constants.js'
    import logins from '$svr/providers/logins.json'
    import Dropdown from '$lib/Dropdown.svelte'
    import VerifyPhone from '$lib/VerifyPhone.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'

    let {
        selected = $bindable(''),
        expanded = $bindable(false),
        addExpanded = $bindable(false),
        phoneOTPState = $bindable(false),
        handleDropdown = () => {},
        continueWithProvider = () => {},
    } = $props()

    async function verifyPhoneSuccess() {
        const res = await getConsent()
        if (!res.merge) {
            global.data.release.phones = res.release.phones
            selected = global.data.release.phones[0]
        }
        expanded = false
        if (addExpanded) {
            addExpanded = false
        }
    }
</script>

<div data-test="phone-container" class="space-y-1">
    <label for="phone" class="text-sm font-medium">
        {$_('Verified Phone Number')}
    </label>
    {#if global.data?.release?.update_scope}
        <div class="box-shadow mt-2 space-y-2 rounded-md p-2">
            <div
                data-test="phones-container"
                class="relative flex items-center"
            >
                <div class="w-full">
                    <ul class="mt-1 flex flex-col items-start space-y-2">
                        {#each dedupe(global.data?.release?.phones) as phone, index (phone)}
                            <li
                                data-test="phone-{index}"
                                class="flex w-full items-center"
                            >
                                <input
                                    id="phone-{phone}"
                                    name="phone"
                                    type="radio"
                                    bind:group={selected}
                                    value={phone}
                                    class="form-radio text-charcoal h-4 w-4 rounded-full dark:text-[#808080]"
                                />
                                <label
                                    for="phone-{phone}"
                                    class="ml-2.5 inline-flex w-full items-center truncate"
                                >
                                    <span class="truncate">
                                        {window.intlTelInputUtils.formatNumber(
                                            phone,
                                            null,
                                            window.intlTelInputUtils
                                                .numberFormat.INTERNATIONAL,
                                        )}
                                    </span>
                                </label>
                            </li>
                        {/each}
                    </ul>
                </div>
            </div>

            <div class="relative">
                <Dropdown
                    hasBackground={false}
                    dataTest="add-provider-btn"
                    ariaLabel="Add another phone"
                    expanded={addExpanded}
                    onclick={() => handleDropdown('addPhone')}
                >
                    <div
                        class="flex h-12 items-center px-4 text-left"
                        aria-hidden="true"
                    >
                        {$_('Add another phone')}
                    </div>

                    {#if addExpanded}
                        <ul
                            class="space-y-2 p-2"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                        >
                            <div class="pb-2">
                                <VerifyPhone
                                    otpState={() => (phoneOTPState = true)}
                                    success={verifyPhoneSuccess}
                                />
                            </div>
                            {#each logins.filter((i) => i.claims.verified_phone) as provider}
                                <LoginProvider
                                    {provider}
                                    prefix={Array.isArray(
                                        global.data?.accounts,
                                    ) &&
                                    global.data?.accounts.includes(
                                        provider.slug,
                                    )
                                        ? 'Get another phone from'
                                        : 'Get phone from'}
                                    onclick={(e) =>
                                        continueWithProvider(
                                            provider.slug,
                                            'phone',
                                            e,
                                        )}
                                />
                            {/each}
                        </ul>
                    {/if}
                </Dropdown>
            </div>
        </div>
    {:else}
        <Dropdown
            hasBackground={false}
            dataTest="phone-dropdown-btn"
            id="phone-dropdown"
            ariaLabel={window.intlTelInputUtils.formatNumber(
                selected,
                null,
                window.intlTelInputUtils.numberFormat.INTERNATIONAL,
            )}
            {expanded}
            onclick={() => handleDropdown('phone')}
        >
            <div
                data-test="selected-phone"
                class="flex h-12 w-full items-center justify-between px-2"
                aria-hidden="true"
            >
                {window.intlTelInputUtils.formatNumber(
                    selected,
                    null,
                    window.intlTelInputUtils.numberFormat.INTERNATIONAL,
                )}
            </div>

            {#if expanded}
                {@const phones = Array.isArray(global.data?.release?.phones)
                    ? dedupe(global.data.release.phones).filter(
                          (i) => i !== selected,
                      )
                    : []}
                <div
                    class="px-indent flex w-full flex-col text-left"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <ul
                        class="mt-0.5 {phones.length
                            ? 'border-y'
                            : 'border-t'} divide-y divide-[#808080] border-[#808080]"
                    >
                        {#each phones as phone, index (phone)}
                            <li>
                                <button
                                    data-test={`phone-${index}`}
                                    class="flex h-12 w-full flex-shrink-0 items-center justify-start"
                                    onclick={() => {
                                        selected = phone
                                        expanded = false
                                    }}
                                >
                                    {window.intlTelInputUtils.formatNumber(
                                        phone,
                                        null,
                                        window.intlTelInputUtils.numberFormat
                                            .INTERNATIONAL,
                                    )}
                                </button>
                            </li>
                        {/each}
                    </ul>

                    <div class="my-2">
                        <VerifyPhone
                            otpState={() => (phoneOTPState = true)}
                            success={verifyPhoneSuccess}
                        />
                    </div>

                    {#if !phoneOTPState && logins.filter((i) => i.claims.verified_phone).length}
                        <ul class="mb-2 space-y-2">
                            {#each logins.filter((i) => i.claims.verified_phone) as provider}
                                <li>
                                    <LoginProvider
                                        onclick={(e) =>
                                            continueWithProvider(
                                                provider.slug,
                                                'phone',
                                                e,
                                            )}
                                        {provider}
                                        prefix="Get phone from"
                                    />
                                </li>
                            {/each}
                        </ul>
                    {/if}
                </div>
            {/if}
        </Dropdown>
    {/if}
</div>

<style>
    @media (prefers-color-scheme: dark) {
        .box-shadow {
            box-shadow: 0 0 0 1px #808080;
        }
    }

    @media (prefers-color-scheme: light) {
        .box-shadow {
            box-shadow: 0 0 0 1px #303030;
        }
    }

    ul li button:hover,
    ul li button:focus {
        box-shadow:
            0px -2px 0px rgb(128, 128, 128),
            0px 2px 0px rgb(128, 128, 128);
    }
</style>
