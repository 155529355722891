<!-- Wallet Authorize -->
<!-- https://wallet.hello.coop/authorize -->

<script>
    import { onDestroy, onMount } from 'svelte'
    import { _ } from 'svelte-i18n'
    import { replace as replaceRoute } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import {
        getConsent,
        postConsent,
        deleteConsent,
        postLinkProvider,
        deleteConsentOnly,
    } from '$utils/api.js'
    import {
        handleConsentResponse,
        saveAuthParams,
        logAuthParams,
        wizard,
        logProviderResPlausibleEvents,
        ERRORS,
        logReleaseFunnelEndPlausible,
        getAppNameFromSessionStorage,
        returnErrorToApp,
    } from '$utils/consent.js'
    import { cleanUrl, preventDefault, getHostName } from '$utils/common.js'
    import { deviceTheme } from '$utils/device.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import { clearSessionStorage } from '$utils/storage.js'
    import { IS_PROD, HELLO_APPS } from '$src/constants.js'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import FullNameSelect from '$lib/release/FullNameSelect.svelte'
    import PreferredNameSelect from '$lib/release/PreferredNameSelect.svelte'
    import FirstNameSelect from '$lib/release/FirstNameSelect.svelte'
    import LastNameSelect from '$lib/release/LastNameSelect.svelte'
    import AppReleaseInfo from '$lib/AppReleaseInfo.svelte'
    import PictureSelect from '$lib/release/PictureSelect.svelte'
    import APIAccessTable from '$lib/release/APIAccessTable.svelte'
    import VerifiedNameSelect from '$lib/release/VerifiedNameSelect.svelte'
    import EthereumSelect from '$lib/release/EthereumSelect.svelte'
    import EmailSelect from '$lib/release/EmailSelect.svelte'
    import PhoneSelect from '$lib/release/PhoneSelect.svelte'
    import ExistingNameSelect from '$lib/release/ExistingNameSelect.svelte'
    import ExistingUsername from '$lib/release/ExistingUsername.svelte'
    import UsernameSelect from '$lib/release/UsernameSelect.svelte'
    import ExternalLinkIcon from '$lib/icon/ExternalLinkIcon.svelte'
    import OrgInfo from '$lib/release/OrgInfo.svelte'
    import Copy from '$lib/Copy.svelte'
    import CopyLink from '$lib/CopyLink.svelte'
    import tippy from 'sveltejs-tippy'

    const otp = $state({
        email: false,
        phone: false,
    })
    const selected = $state({
        name: null,
        email: null,
        phone: null,
        ethAddress: null,
        picture: null,
        preferredName: null,
        firstName: null,
        lastName: null,
        discord: null,
        twitter: null,
        github: null,
        gitlab: null,
        verifiedName: null,
        existingName: null,
        existingUsername: null,
    })
    const dropdown = $state({
        verifiedNameAccounts: false,
        existingNameAccounts: false,
        existingUsernameAccounts: false,
        name: false,
        addName: false, // update scope add dropdown
        preferredName: false,
        addPreferredName: false, // update scope add dropdown
        firstName: false,
        lastName: false,
        email: false,
        addEmail: false, // update scope add dropdown
        phone: false,
        addPhone: false, // update scope add dropdown
        picture: false,
        addPicture: false,
        ethAddress: false,
        discordAccounts: false,
        twitterAccounts: false,
        githubAccounts: false,
        gitlabAccounts: false,
    })
    let loadingFailed = $state(false)
    let noSession = $state(false)
    let unsupportedRedirectUri = $state(false)
    let unconfiguredRedirectUri = $state(false)
    let accessDenied = $state(false)
    let authCancelledAtInitClient = $state(false)
    let authCancelledAtRemoteClient = $state(false)
    let remoteAuthDone = $state(false)
    let invalidQr = $state(false)
    let errData = $state(null)
    let evtSource

    const isConsoleOrQuickstartApp = $derived(
        global.data?.release?.console || global.data?.release?.quickstart,
    )
    const hasSingleClaimRequested = $derived(
        global.data?.release && Object.keys(global.data?.release)?.length === 1,
    )
    const isNotHelloApp = $derived(
        !HELLO_APPS.includes(global.data?.app?.client_id),
    )
    // continue button is disabled until all dropdowns are closed i.e. all claims selected
    const disableContinue = $derived(Object.values(dropdown).some((i) => i))

    // TBD move to onMount -- we are currently using these var outside onmount fn
    const search = window.location.search
    const hash = window.location.hash.substring(1) // strips '#'
    const params = new URLSearchParams(search || hash)

    onMount(async () => {
        // Process URL State
        const client_id = params.get('client_id')
        const redirect_uri = params.get('redirect_uri')
        const response_type = params.get('response_type')
        const error = params.get('error')
        const qr = params.get('qr')
        const provider = params.get('provider')

        const isAuthReq = client_id || response_type || redirect_uri
        const noConsentState = !global.data?.version

        cleanUrl() // we got all the url state we needed so clean it up

        if (isAuthReq) await processAuthReq()
        else if (qr) await processQr()
        // error & provider order matters currently -- will rework once error handling is reworked
        else if (error) await processError()
        else if (provider) await processResponse()
        else if (noConsentState) await handleNoConsentState()

        // Process API Response
        const respondBackToApp = global.data.uri && global.data.response_mode
        const remoteAuthSuccess = global.data.success || global.data.inApp
        const isLoggedIn =
            global.data.isPersonalLoggedIn || global.data?.isManagedLoggedIn
        const isManagedWizard =
            global.data.chooseWhoManages ||
            global.data.chooseManagedLogo ||
            global.data.verifyManagedEmail
        const upgradeWizard = global.data.upgradePreferred
        const promptLogin =
            !loadingFailed &&
            !isManagedWizard &&
            !upgradeWizard &&
            (!isLoggedIn ||
                !global.data.release ||
                global.data.login_hint ||
                global.data.release?.requiresPersonal)

        if (respondBackToApp) {
            if (!global.data?.params?.error) {
                await logPlausibleEvent({ u: '/auto' })
            }
            return handleConsentResponse(global.data)
        }

        if (remoteAuthSuccess) return handleRemoteAuthSuccess()

        if (promptLogin) return replaceRoute('/login')

        if (global.isRemoteAuthClient) handleRemoteAuthClient()

        // Redirects to wizard pages if unfulfilled release
        if (!loadingFailed && !wizard(global.data)) {
            logPlausibleEvent({ u: '/' })
            setStartingClaimValues()
            global.spinner = false
        }

        // End of processing

        // TBD move below stuff outside to cleanup onmount fn
        async function processAuthReq() {
            const isValidAuthReq = client_id && response_type && redirect_uri
            if (!isValidAuthReq) {
                // tbd: handle client side
            }

            saveAuthParams(params)

            const json = await getConsent(params.toString())
            if (json.error) return handleGetConsentError(json)

            global.data = json

            if (!IS_PROD) logAuthParams(params)

            clearSessionStorage([
                'app',
                'authorize_query_params',
                'az_release_funnel',
            ])
        }

        async function processQr() {
            const json = await getConsent(params.toString())
            if (json.error) {
                notification.show('QR has already been scanned', 'error')
                invalidQr = true
                loadingFailed = true
                global.spinner = false
            } else {
                global.data = json
                if (!IS_PROD) logAuthParams(params)
            }
        }

        async function processResponse() {
            params.append('prefers-color-scheme', deviceTheme)
            params.append('language', window.navigator.language)
            const json = await getConsent(params.toString())
            if (json.error) {
                const msg = json.error?.message
                // user clicked back after we have already processed response from provider
                // in this case, we fetch get consent again
                if (msg === 'NO_SESSION_RESPONSE') {
                    global.data = await getConsent()
                    if (global.data?.verifyManagedEmail)
                        notification.show(
                            $_('You must verify your email to proceed'),
                            'error',
                        )
                    else
                        notification.show(
                            $_('Looks like you clicked the back button'),
                            'error',
                        )
                } else {
                    return handleGetConsentError(json)
                }
            } else {
                global.data = json
            }
            logProviderResPlausibleEvents(params, global.data)
        }

        async function processError() {
            //this will always fail
            const json = await getConsent(params.toString())
            //normalize error to be lowercase because LINE sends ACCESS_DENIED (all uppercase)
            const txt =
                (typeof json?.error === 'string' &&
                    ERRORS[json.error.toLowerCase()]) ||
                $_('Something went wrong. Please try again later.')
            notification.show(txt, 'error')

            //get consent again since we want to show the previous state the user was in
            global.data = await getConsent(null, { showNotification: false }) //do not override the oauth error notification
        }

        async function handleNoConsentState() {
            if (sessionStorage.authorize_query_params) {
                const json = await getConsent()
                if (json.error) return handleGetConsentError(json)
                global.data = json
            } else {
                return (window.location.href = window.location.origin)
            }
        }

        async function handleRemoteAuthSuccess() {
            remoteAuthDone = true
            notification.show('Authorization is complete', 'success')
            if (evtSource) {
                evtSource.close()
            }
            return (global.spinner = false)
        }

        function handleRemoteAuthClient() {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }
    })

    onDestroy(() => {
        if (evtSource) evtSource.close()
    })

    async function handleGetConsentError(err) {
        errData = err

        if (errData?.uri) {
            //if no response mode, defaults to fragment
            return handleConsentResponse(errData)
        }

        if (err?.error?.message === 'NO_SESSION_REQUEST') {
            await logPlausibleEvent({ u: '/no-session' })
            noSession = true
        } else if (err?.error?.message === 'UNSUPPORTED_REDIRECT_URI') {
            await logPlausibleEvent({ u: '/unsupported-redirect-uri' })
            unsupportedRedirectUri = true
        } else if (err?.error?.message === 'UNCONFIGURED_REDIRECT_URI') {
            await logPlausibleEvent({ u: '/unconfigured-redirect-uri' })
            unconfiguredRedirectUri = true
        } else if (err?.error?.message === 'ACCESS_DENIED') {
            await logPlausibleEvent({ u: '/access-denied' })
            accessDenied = true
        } else if (errData?.error === 'MISSING_PARAMETER' && errData?.param) {
            notification.show(errData.param + ' is missing', 'error') // i18n
        } else {
            notification.show(
                ERRORS[errData?.error] ||
                    $_('Something went wrong. Please try again later.'),
                'error',
            )
        }
        if (!IS_PROD) {
            logAuthParams(params)
            console.log('Response:', JSON.stringify(errData, null, 2))
        }

        window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
        )
        loadingFailed = true
        global.spinner = false
    }

    function setStartingClaimValues() {
        const release = global.data.release
        if (Object.keys(release).length === 1 && release?.sub) return
        if (release.verified_names) {
            const firstValidOccurrence = Object.keys(
                release.verified_names,
            ).filter(
                (i) => Object.keys(release.verified_names[i]).length >= 2,
            )[0]
            selected.verifiedName = firstValidOccurrence
        }
        if (release.existing_names) {
            const firstValidOccurrence = Object.keys(release.existing_names)[0]
            selected.existingName = firstValidOccurrence
        }
        if (release.existing_usernames) {
            const firstValidOccurrence = Object.keys(
                release.existing_usernames,
            )[0]
            selected.existingUsername = firstValidOccurrence
        }
        selected.preferredName = release.nicknames?.[0] || null
        selected.firstName =
            release.given_names?.[0] || release.managed?.given_name || null
        selected.lastName =
            release.family_names?.[0] || release.managed?.family_name || null
        selected.discord = release.discords?.[0] || null
        selected.twitter = release.twitters?.[0] || null
        selected.github = release.githubs?.[0] || null
        selected.gitlab = release.gitlabs?.[0] || null

        if (
            Array.isArray(release?.names) &&
            release?.update_scope &&
            release?.previous?.name
        ) {
            const previous = release?.previous?.name
            const previousClaimExists = release?.names.includes(previous)
            selected.name = previousClaimExists
                ? release?.previous.name
                : release.names?.[0]
        } else if (release?.managed?.name) {
            selected.name = release?.managed?.name
        } else {
            selected.name = release.names?.[0] || null
        }

        if (
            Array.isArray(release?.emails) &&
            release?.update_scope &&
            release?.previous?.email
        ) {
            const previous = release?.previous?.email
            const previousClaimExists = release?.emails.includes(previous)
            selected.email = previousClaimExists
                ? release?.previous.email
                : release.emails?.[0]
        } else if (release?.managed?.email) {
            selected.email = release?.managed?.email
        } else {
            selected.email = release.emails?.[0] || null
        }

        if (
            Array.isArray(release?.phones) &&
            release?.update_scope &&
            release?.previous?.phone
        ) {
            const previous = release?.previous?.phone
            const previousClaimExists = release?.phones.includes(previous)
            selected.phone = previousClaimExists
                ? release?.previous.phone
                : release.phones?.[0]
        } else {
            selected.phone = release.phones?.[0] || null
        }

        if (
            Array.isArray(release?.pictures) &&
            release?.update_scope &&
            release?.previous?.picture
        ) {
            //'previous' property only returns 'picture' value of picture object
            //tbd => if no pictureobj found, pick first
            const previous = release?.previous?.picture
            const pictureObj = release?.pictures.find(
                (i) => i.picture === previous,
            )
            selected.picture = pictureObj || release.pictures?.[0]
        } else if (release?.managed?.picture) {
            selected.picture = release?.managed?.picture
        } else {
            selected.picture = release.pictures?.[0] || null
        }

        if (
            Array.isArray(release?.ethereums) &&
            release?.update_scope &&
            release?.previous
        ) {
            //'previous' property only returns 'id' value of ethereum object
            const previous = release?.previous?.ethereum
            const ethObj = release?.ethereums.find(
                (i) => i.address === previous,
            )
            selected.ethAddress = ethObj || release.ethereums?.[0]
        } else {
            selected.ethAddress = release.ethereums?.[0] || null
        }
    }

    function handleDropdown(toggledDropdown) {
        for (const i in dropdown) {
            if (i === toggledDropdown) {
                dropdown[i] = !dropdown[i]
                continue
            }
            dropdown[i] = false
        }
        otp.email = otp.phone = false
    }

    async function submit() {
        global.spinner = true
        const body = {}
        if (global.data.release?.managed) {
            body.managed = {}
        }
        if (selected.verifiedName && global.data.release?.verified_names) {
            body.verified_name = selected.verifiedName
        }
        if (selected.existingName && global.data.release?.existing_names) {
            body.existing_name = selected.existingName
        }
        if (
            selected.existingUsername &&
            global.data.release?.existing_usernames
        ) {
            body.existing_username = selected.existingUsername
        }
        if (selected.name) {
            if (global.data.release?.managed?.name) {
                body.managed.name = selected.name
            } else if (global.data.release?.names) {
                body.name = {
                    value: selected.name,
                    ordinal: global.data.release.names.indexOf(selected.name),
                }
            }
        }
        if (selected.preferredName && global.data.release?.nicknames) {
            body.nickname = {
                value: selected.preferredName,
                ordinal: global.data.release.nicknames.indexOf(
                    selected.preferredName,
                ),
            }
        }
        if (selected.firstName) {
            if (global.data.release?.managed?.given_name) {
                body.managed.given_name = selected.firstName
            } else if (global.data.release?.given_names) {
                body.given_name = {
                    value: selected.firstName,
                    ordinal: global.data.release.given_names.indexOf(
                        selected.firstName,
                    ),
                }
            }
        }
        if (selected.lastName) {
            if (global.data.release?.managed?.family_name) {
                body.managed.family_name = selected.lastName
            } else if (global.data.release?.family_names) {
                body.family_name = {
                    value: selected.lastName,
                    ordinal: global.data.release.family_names.indexOf(
                        selected.lastName,
                    ),
                }
            }
        }
        if (selected.email) {
            if (global.data.release?.managed?.email) {
                body.managed.email = selected.email
            } else if (global.data.release?.emails) {
                body.email = {
                    value: selected.email,
                    ordinal: global.data.release.emails.indexOf(selected.email),
                }
            }
        }
        if (selected.phone && global.data.release?.phones) {
            body.phone = {
                value: selected.phone,
                ordinal: global.data.release.phones.indexOf(selected.phone),
            }
        }
        if (selected.ethAddress && global.data.release?.ethereums) {
            const ethObj = {
                value: selected.ethAddress.address,
                ordinal: global.data.release?.ethereums.findIndex(
                    (i) => i.address === selected.ethAddress.address,
                ),
            }
            body.ethereum = ethObj
        }
        if (selected.picture) {
            if (global.data.release?.managed?.picture) {
                body.managed.picture = selected.picture
            } else if (global.data.release?.pictures) {
                body.picture = {
                    value: selected.picture.picture,
                    ordinal: global.data.release.pictures.findIndex(
                        (i) => i.picture === selected.picture.picture,
                    ),
                }
            }
        }
        if (selected.discord?.id && global.data.release?.discords) {
            body.discord = {
                value: selected.discord, //{id, username}
                ordinal: global.data.release.discords.findIndex(
                    (i) => i.id === selected.discord.id,
                ),
            }
        }
        if (selected.twitter?.id && global.data.release?.twitters) {
            body.twitter = {
                value: selected.twitter, //{id, username}
                ordinal: global.data.release.twitters.findIndex(
                    (i) => i.id === selected.twitter.id,
                ),
            }
        }
        if (selected.github?.id && global.data.release?.githubs) {
            body.github = {
                value: selected.github, //{id, username}
                ordinal: global.data.release.githubs.findIndex(
                    (i) => i.id === selected.github.id,
                ),
            }
        }
        if (selected.gitlab?.id && global.data.release?.gitlabs) {
            body.gitlab = {
                value: selected.gitlab, //{id, username}
                ordinal: global.data.release.gitlabs.findIndex(
                    (i) => i.id === selected.gitlab.id,
                ),
            }
        }
        if (global.data.release?.console) body.console = true
        if (global.data.release?.quickstart) body.quickstart = true
        if (global.data.release?.recovery) body.recovery = true

        const res = await postConsent(body)
        await logReleaseFunnelEndPlausible(global.data)
        if (global.isRemoteAuthClient) {
            global.spinner = false
            remoteAuthDone = true
            notification.show('Authorization is complete', 'success')
            evtSource.close()
        } else {
            return handleConsentResponse(res)
        }
    }

    async function continueWithProvider(slug, attribute, server) {
        global.spinner = true
        const { redirect } = await postLinkProvider({
            slug,
            attribute,
            server,
        })
        window.location.href = redirect
    }

    async function cancelConsent() {
        sessionStorage.removeItem('az_release_funnel')
        if (global.isRemoteAuthClient) {
            await deleteConsentOnly()
            notification.show('Authorization was cancelled', 'error')
            authCancelledAtRemoteClient = true
            if (evtSource) {
                evtSource.close()
            }
        } else {
            global.spinner = true
            deleteConsent()
        }
    }
</script>

<AuthorizeLayout
    heading={$_('Requires your')}
    showHeading={global.data?.release &&
        Object.keys(global.data.release).length}
    showTitleBar={!authCancelledAtRemoteClient &&
        !authCancelledAtInitClient &&
        !remoteAuthDone &&
        Object.keys(global.data).length &&
        !global.data?.uri}
    showDeviceInfo={global.isRemoteAuthClient &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient &&
        !remoteAuthDone}
    closePageState={authCancelledAtInitClient ||
        authCancelledAtRemoteClient ||
        remoteAuthDone ||
        invalidQr}
    showCancelConsent={false}
    {cancelConsent}
>
    <section class="mx-auto max-w-md">
        <div class="relative space-y-2">
            <!-- Hide API Access section for first party apps unless console is the only requested scope -->
            {#if isConsoleOrQuickstartApp && (hasSingleClaimRequested || isNotHelloApp)}
                <APIAccessTable />
            {/if}

            {#if selected.name}
                <FullNameSelect
                    bind:selected={selected.name}
                    bind:expanded={dropdown.name}
                    bind:addExpanded={dropdown.addName}
                    {handleDropdown}
                />
            {/if}

            {#if selected.firstName}
                <FirstNameSelect
                    bind:selected={selected.firstName}
                    bind:expanded={dropdown.firstName}
                    {handleDropdown}
                />
            {/if}

            {#if selected.lastName}
                <LastNameSelect
                    bind:selected={selected.lastName}
                    bind:expanded={dropdown.lastName}
                    {handleDropdown}
                />
            {/if}

            {#if selected.email}
                <EmailSelect
                    bind:selected={selected.email}
                    bind:expanded={dropdown.email}
                    bind:addExpanded={dropdown.addEmail}
                    bind:emailOTPState={otp.email}
                    {handleDropdown}
                    {continueWithProvider}
                />
            {/if}

            {#if selected.picture}
                <PictureSelect
                    bind:selected={selected.picture}
                    bind:expanded={dropdown.picture}
                    bind:addExpanded={dropdown.addPicture}
                    {handleDropdown}
                    {continueWithProvider}
                />
            {/if}

            {#if global.data?.release?.managed?._meta}
                <OrgInfo />
            {/if}

            {#if selected.phone}
                <PhoneSelect
                    bind:selected={selected.phone}
                    bind:expanded={dropdown.phone}
                    bind:addExpanded={dropdown.addPhone}
                    bind:phoneOTPState={otp.phone}
                    {handleDropdown}
                    {continueWithProvider}
                />
            {/if}

            {#if selected.preferredName}
                <PreferredNameSelect
                    bind:selected={selected.preferredName}
                    bind:expanded={dropdown.preferredName}
                    {handleDropdown}
                />
            {/if}

            {#if selected.ethAddress}
                <EthereumSelect
                    bind:selected={selected.ethAddress}
                    bind:expanded={dropdown.ethAddress}
                    {handleDropdown}
                />
            {/if}

            {#if selected.verifiedName}
                <VerifiedNameSelect
                    bind:selected={selected.verifiedName}
                    bind:expanded={dropdown.verifiedNameAccounts}
                    {handleDropdown}
                    {continueWithProvider}
                />
            {/if}

            {#if selected.existingName && !selected.verifiedName}
                <ExistingNameSelect
                    bind:selected={selected.existingName}
                    bind:expanded={dropdown.existingNameAccounts}
                    {handleDropdown}
                    {continueWithProvider}
                />
            {/if}

            {#if selected.existingUsername}
                <ExistingUsername
                    bind:selected={selected.existingUsername}
                    bind:expanded={dropdown.existingUsernameAccounts}
                    {handleDropdown}
                    {continueWithProvider}
                />
            {/if}

            {#if selected.discord}
                <UsernameSelect
                    provider="discord"
                    label={$_('Discord Username')}
                    selected={selected.discord}
                    bind:expanded={dropdown.discordAccounts}
                    expandedKey="discordAccounts"
                    {handleDropdown}
                    {continueWithProvider}
                />
            {/if}

            {#if selected.twitter}
                <UsernameSelect
                    provider="twitter"
                    label={$_('X (Twitter) Username')}
                    selected={selected.twitter}
                    bind:expanded={dropdown.twitterAccounts}
                    expandedKey="twitterAccounts"
                    {handleDropdown}
                    {continueWithProvider}
                />
            {/if}

            {#if selected.github}
                <UsernameSelect
                    provider="github"
                    label={$_('GitHub Username')}
                    selected={selected.github}
                    bind:expanded={dropdown.githubAccounts}
                    expandedKey="githubAccounts"
                    {handleDropdown}
                    {continueWithProvider}
                />
            {/if}

            {#if selected.gitlab}
                <UsernameSelect
                    provider="gitlab"
                    label={$_('GitLab Username')}
                    selected={selected.gitlab}
                    bind:expanded={dropdown.gitlabAccounts}
                    expandedKey="gitlabAccounts"
                    {handleDropdown}
                    {continueWithProvider}
                />
            {/if}
        </div>

        {#if global.data?.app}
            <AppReleaseInfo app={global.data.app} />
        {/if}

        <div
            class="unstack:flex-row flex flex-col-reverse justify-between gap-y-4"
        >
            <button
                data-test="cancel-btn"
                onclick={preventDefault(cancelConsent)}
                class="btn-border unstack:w-36">{$_('Cancel')}</button
            >
            <!-- svelte-ignore a11y_autofocus -->
            {#if disableContinue}
                <button
                    data-test="continue-btn"
                    class="btn-background unstack:w-36 cursor-not-allowed opacity-50"
                    use:tippy={{
                        content: $_(
                            'Select values or close all dropdowns to continue',
                        ),
                    }}
                >
                    {$_('Continue')}
                </button>
            {:else}
                <button
                    autofocus={window && window.innerHeight > 1000}
                    data-test="continue-btn"
                    class="btn-background unstack:w-36"
                    onclick={submit}
                >
                    {$_('Continue')}
                </button>
            {/if}
        </div>
    </section>
</AuthorizeLayout>

{#if loadingFailed}
    <div class="p-10 text-center">
        {#if noSession}
            No Session - <a
                href={window.location.origin}
                class="hover:underline focus:underline">Go to Hellō Wallet</a
            >
        {:else}
            {@const searchParams = new URLSearchParams(
                sessionStorage.authorize_query_params,
            )}
            {@const appName = getAppNameFromSessionStorage()}
            {@const clientId = searchParams.get('client_id')}
            {@const redirectUri =
                errData?.redirect_uri || searchParams.get('redirect_uri')}
            {@const hostName = getHostName(redirectUri)}
            {@const walletUrl = new URL(window.location.origin)}
            {@const walletHostnameParts = walletUrl.hostname.split('.')}
            {@const walletSubdomain = walletHostnameParts.slice(-2).join('.')}
            {@const console =
                `https://console.${walletSubdomain}/` +
                (clientId ? '?client_id=' + clientId : '')}

            {#if unsupportedRedirectUri}
                <div class="mb-12 flex flex-col items-center">
                    {#if clientId}
                        <span
                            class="flex items-center space-x-2 rounded-md bg-red-500 px-6 py-2 text-white dark:text-[#d4d4d4]"
                            data-test="error-notification"
                        >
                            <span
                                >{`The following redirect_uri is not configured for client_id ${clientId}`}</span
                            >
                            <Copy content={clientId} />
                        </span>
                    {/if}
                    {#if redirectUri}
                        <CopyLink
                            link={redirectUri}
                            dataTest="error-redirect-uri"
                        />
                    {/if}
                    <p class="mt-6">
                        {$_('Please report this to the app developer')}
                    </p>
                </div>
            {:else if unconfiguredRedirectUri}
                <div class="mb-12 flex flex-col items-center">
                    {#if clientId}
                        <span
                            class="flex items-center space-x-2 rounded-md bg-red-500 px-6 py-2 text-white dark:text-[#d4d4d4]"
                            data-test="error-notification"
                        >
                            <span
                                >{`The following redirect_uri is not configured for client_id ${clientId}`}</span
                            >
                            <Copy content={clientId} />
                        </span>
                    {/if}
                    {#if redirectUri}
                        <CopyLink
                            link={redirectUri}
                            dataTest="error-redirect-uri"
                        />
                    {/if}
                    <p class="mt-6">
                        To resolve, add it as a redirect_uri in the
                    </p>
                    {#if console}
                        <a
                            href={console}
                            target="_blank"
                            data-test="redirect-fix-dev-console-link"
                            class="mt-2 flex items-center space-x-1 underline"
                        >
                            <span>Hellō Developer Console</span>
                            <ExternalLinkIcon css="h-4 mt-0.5" />
                        </a>
                    {/if}
                </div>
            {:else if accessDenied}
                <div class="mb-12 flex flex-col items-center">
                    {#if clientId}
                        <span
                            class="rounded-md bg-red-500 px-6 py-2 text-white dark:text-[#d4d4d4]"
                            data-test="error-notification"
                        >
                            {`You do not have access to the following redirect_uri`}</span
                        >
                    {/if}
                    {#if redirectUri}
                        <CopyLink
                            link={redirectUri}
                            dataTest="error-redirect-uri"
                        />
                    {/if}
                </div>
            {/if}

            <!-- If hostName, it means we have a valid redirect_uri -->
            {#if hostName}
                <button
                    onclick={() => returnErrorToApp(errData)}
                    class="hover:underline focus:underline"
                    data-test="return-uri-text"
                    >Return to {appName || hostName || redirectUri}</button
                >
            {:else}
                <button
                    onclick={() => history.back()}
                    data-test="return-uri-text"
                    class="hover:underline focus:underline">Go back</button
                >
            {/if}
        {/if}
    </div>
{/if}
