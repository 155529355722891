<script>
    import { _ } from 'svelte-i18n'
    import {
        push as navigateTo,
        replace as replaceRoute,
    } from 'svelte-spa-router'
    import Modal from '$lib/modal/Modal.svelte'
    import VerifyOTP from '$lib/VerifyOTP.svelte'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { onDestroy, onMount } from 'svelte'
    import {
        getConsent,
        postLinkEmail,
        postVerifyEmail,
        postLinkEmailCode,
        postVerifyEmailCode,
        postLinkProvider,
    } from '$utils/api.js'
    import logins from '$svr/providers/logins.json'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import { dedupe, preventDefault } from '$utils/common.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import { isValidEmail } from '$utils/email.js'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import { IS_PROD } from '$src/constants.js'

    let value = $state('')
    let showOTPModal = $state(false)
    let ajaxRequest = $state(false)

    let ajaxRequestSendOTP = $state(false)
    let ajaxRequestResendOTP = $state(false)
    let otp = $state('')

    let authCancelledAtInitClient = $state(false)
    let authCancelledAtRemoteClient = $state(false)
    let selectedManagedEmail = $state()
    let evtSource = $state(null)

    onMount(async () => {
        global.spinner = true

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent()
        }

        if (!global.data?.isPersonalLoggedIn && !global.data?.isManagedLoggedIn)
            return replaceRoute('/login')

        //we already got an email
        if (
            global.data.release?.emails?.length &&
            !global.data?.verifyManagedEmail
        )
            return replaceRoute('/')

        //populate email input field with unverified email
        if (global.data.release?.unverified_emails?.length)
            value = global.data.release?.unverified_emails[0]

        if (global.isRemoteAuthClient) {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }

        if (global.data?.verifyManagedEmail)
            selectedManagedEmail = global.data?.verifyManagedEmail.emails[0]

        logPlausibleEvent({ u: '/wizard/email' })

        global.spinner = false
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
    })

    async function verifyEmail(resend = false) {
        ajaxRequest = true
        // Check if user is trying to add unverified email
        if (
            (global.data.release?.unverified_emails?.length &&
                global.data.release?.unverified_emails.includes(value)) ||
            selectedManagedEmail
        ) {
            resend
                ? await postVerifyEmail(value || selectedManagedEmail, true)
                : await postVerifyEmail(value || selectedManagedEmail)
        } else {
            resend
                ? await postLinkEmail(value, true)
                : await postLinkEmail(value)
        }

        if (selectedManagedEmail) showOTPModal = selectedManagedEmail
        else showOTPModal = true

        if (resend) otp = ''

        ajaxRequest = ajaxRequestResendOTP = false
    }

    async function verifyEmailCode(code) {
        ajaxRequestSendOTP = true
        //Check if user is trying to add unverified email
        let json
        if (
            (global.data.release?.unverified_emails?.length &&
                global.data.release?.unverified_emails.includes(value)) ||
            selectedManagedEmail
        ) {
            json = await postVerifyEmailCode(code)
        } else {
            json = await postLinkEmailCode(code, false)
        }
        if (json.error) {
            otp = ''
            ajaxRequestSendOTP = false
        } else {
            notification.clear() //remove outstanding notifications
            global.data = {} //get latest state from / root page
            navigateTo('/')
        }
    }

    async function continueWithProvider(slug, server) {
        global.spinner = true
        const { redirect } = await postLinkProvider({
            slug,
            attribute: 'email',
            server,
        })
        window.location.href = redirect
    }
</script>

<AuthorizeLayout
    showHeading={!global.data?.verifyManagedEmail}
    heading={$_('Requires your email')}
    showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
    showDeviceInfo={global.isRemoteAuthClient &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
    unstack={false}
    {evtSource}
    bind:authCancelledAtRemoteClient
>
    {#if global.data?.verifyManagedEmail}
        {@const managed = global.data?.verifyManagedEmail}
        <h1 class="text-lg">{$_('Please verify the email to be used with')}</h1>
        <h1 data-test="domain" class="px-4 text-lg font-semibold">
            {managed.domain}
        </h1>

        <div class="my-6 space-y-5">
            {#if Array.isArray(managed.emails) && [...new Set(managed.emails)].length > 1}
                <div
                    class="bg-charcoal mt-2 space-y-2 rounded-md bg-opacity-10 p-2 dark:bg-opacity-100"
                >
                    <div
                        data-test="emails-container"
                        class="relative flex items-center"
                    >
                        <div class="w-full">
                            <ul
                                class="mt-1 flex flex-col items-start space-y-2"
                            >
                                {#each dedupe(managed.emails) as email, index (email)}
                                    <li
                                        data-test="email-{index}"
                                        class="relative flex w-full items-center"
                                    >
                                        <input
                                            id="email-{email}"
                                            name="email"
                                            type="radio"
                                            bind:group={selectedManagedEmail}
                                            checked={index === 0}
                                            value={email}
                                            class="form-radio text-charcoal h-4 w-4 rounded-full dark:text-[#808080]"
                                        />
                                        <label
                                            for="email-{email}"
                                            class="ml-2.5 inline-flex w-full items-center truncate"
                                        >
                                            <span class="truncate">
                                                {email}
                                            </span>
                                        </label>

                                        {#if showOTPModal === email}
                                            <Modal
                                                position="center"
                                                css="top-8"
                                                close={() => {
                                                    showOTPModal = false
                                                    otp = ''
                                                }}
                                            >
                                                <VerifyOTP
                                                    bind:otp
                                                    bind:ajaxRequestSendOTP
                                                    bind:ajaxRequestResendOTP
                                                    verifyCode={verifyEmailCode}
                                                    resend={() =>
                                                        verifyEmail(true)}
                                                />
                                            </Modal>
                                        {/if}
                                    </li>
                                {/each}
                            </ul>
                        </div>
                    </div>
                </div>
            {:else}
                <div class="relative">
                    <div
                        class="bg-charcoal block flex h-12 items-center overflow-hidden rounded-md bg-opacity-10 px-4 dark:bg-opacity-100"
                    >
                        {selectedManagedEmail}
                    </div>
                    {#if showOTPModal === selectedManagedEmail}
                        <Modal
                            position="center"
                            css="top-10"
                            close={() => {
                                showOTPModal = false
                                otp = ''
                            }}
                        >
                            <VerifyOTP
                                bind:otp
                                bind:ajaxRequestSendOTP
                                bind:ajaxRequestResendOTP
                                verifyCode={verifyEmailCode}
                                resend={() => verifyEmail(true)}
                            />
                        </Modal>
                    {/if}
                </div>
            {/if}

            <button
                data-test="email-send-verification-btn"
                onclick={() => verifyEmail(false)}
                disabled={ajaxRequest}
                class="btn-background relative inline-flex h-12 w-full items-center justify-center transition disabled:opacity-60"
            >
                {#if ajaxRequest}
                    <SpinnerIcon css="h-5 w-5 text-white" />
                {:else}
                    {$_('Send verification code')}
                {/if}
            </button>
        </div>
    {:else}
        <form
            class="mx-auto max-w-md"
            onsubmit={preventDefault(() => verifyEmail(false))}
        >
            <div class="relative">
                <!-- svelte-ignore a11y_autofocus -->
                <input
                    id="email"
                    bind:value
                    type="email"
                    name="email"
                    class="h-12 w-full px-4"
                    required
                    autofocus
                    autocomplete="email"
                    autocapitalize="off"
                    placeholder={$_('enter your email')}
                />

                {#if showOTPModal}
                    <Modal
                        position="center"
                        close={() => {
                            showOTPModal = false
                            otp = ''
                        }}
                    >
                        <VerifyOTP
                            bind:otp
                            bind:ajaxRequestSendOTP
                            bind:ajaxRequestResendOTP
                            verifyCode={verifyEmailCode}
                            on:resend={() => verifyEmail(true)}
                        />
                    </Modal>
                {/if}
            </div>

            <div class="my-2 flex items-center justify-between">
                <button
                    data-test="send-verification-code-btn"
                    type="submit"
                    disabled={!isValidEmail(value) || ajaxRequest}
                    class="btn-background inline-flex w-full items-center justify-center transition disabled:opacity-50"
                >
                    {#if ajaxRequest}
                        <SpinnerIcon css="h-5 w-5 text-white" />
                    {:else}
                        {$_('Send verification code')}
                    {/if}
                </button>
            </div>
        </form>

        <span class="my-4 block text-center text-lg uppercase opacity-60">
            {$_('Or')}
        </span>

        <section class="space-y-2">
            {#each logins.filter((i) => i.claims.verified_email) as provider}
                <LoginProvider
                    onclick={(e) => continueWithProvider(provider.slug, e)}
                    {provider}
                    prefix="Get email from"
                />
            {/each}
        </section>
    {/if}
</AuthorizeLayout>
