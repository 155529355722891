<script>
    import { _ } from 'svelte-i18n'
    import {
        push as navigateTo,
        replace as replaceRoute,
    } from 'svelte-spa-router'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { onDestroy, onMount } from 'svelte'
    import {
        getConsent,
        postLinkProvider,
        postUpgradePreferredLater,
        postUpgradePreferredNever,
    } from '$utils/api.js'
    import { logPlausibleEvent } from '$utils/plausible.js'
    import AuthorizeLayout from '$lib/layout/AuthorizeLayout.svelte'
    import { IS_PROD } from '$src/constants.js'
    import LoggedInSubject from '$lib/login/LoggedInSubject.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'
    import RecommendedProviders from '$lib/login/RecommendedProviders.svelte'
    import { processProviderHintParam } from '$utils/consent.js'

    let authCancelledAtRemoteClient = $state(false)
    let evtSource = $state(null)
    let authCancelledAtInitClient = $state(false)
    let recommendedProviders = $state([])

    const preferred = $derived(global.data?.preferred?.[0])

    onMount(async () => {
        global.spinner = true

        //we dont have consent data
        if (!global.data?.version) {
            global.data = await getConsent()
        }

        if (!global.data?.isPersonalLoggedIn && !global.data?.isManagedLoggedIn)
            return replaceRoute('/login')

        if (!global.data?.upgradePreferred) return replaceRoute('/')

        if (global.isRemoteAuthClient) {
            evtSource = new EventSource('/api/v1/login/qrcode/status')
            evtSource.addEventListener('cancel', () => {
                notification.show(
                    'Authorization was cancelled on the other device',
                    'error',
                )
                authCancelledAtInitClient = true
                evtSource.close()
            })
            evtSource.addEventListener('keep-alive', (event) => {
                if (!IS_PROD) {
                    console.log('keep-alive: ' + event.data)
                }
            })
        }

        const authorizeQueryParams = sessionStorage.getItem(
            'authorize_query_params',
        )
        // superset of apple, google, microsoft + provider_hints
        recommendedProviders = [
            ...new Set([
                'apple',
                'google',
                'microsoft',
                ...processProviderHintParam(authorizeQueryParams, global.data),
            ]),
        ].filter((provider) => !['email', 'ethereum'].includes(provider)) // only show social options

        logPlausibleEvent({ u: '/wizard/upgrade' })

        global.spinner = false
    })

    onDestroy(() => {
        if (evtSource) {
            evtSource.close()
        }
    })

    async function continueWithProvider({ slug } = {}) {
        global.spinner = true
        const { redirect } = await postLinkProvider({
            slug,
            body: {
                upgradePreferred: slug, // pass this so server makes linked account as preferred
            },
        })
        window.location.href = redirect
    }

    async function remindLater() {
        global.spinner = true
        await postUpgradePreferredLater()
        global.data = {} // fetch latest data at root
        return navigateTo('/')
    }

    async function dontAskAgain() {
        global.spinner = true
        await postUpgradePreferredNever()
        global.data = {} // fetch latest data at root
        return navigateTo('/')
    }
</script>

<AuthorizeLayout
    showTitleBar={!authCancelledAtRemoteClient && !authCancelledAtInitClient}
    showDeviceInfo={global.isRemoteAuthClient &&
        !authCancelledAtRemoteClient &&
        !authCancelledAtInitClient}
    closePageState={authCancelledAtRemoteClient || authCancelledAtInitClient}
    {evtSource}
    bind:authCancelledAtRemoteClient
    showCancelConsent={false}
>
    <LoggedInSubject
        heading={$_('You are logged in with an email address')}
        userName={preferred?.user_name}
        description={$_(
            'Logging in with a social provider is generally more secure than email',
        )}
    >
        {#snippet svg()}
            <MailIcon size="lg" />
        {/snippet}
    </LoggedInSubject>

    <RecommendedProviders
        accountSelected="personal"
        label={$_('Select your preferred login provider')}
        showEmailLogin={false}
        {recommendedProviders}
        {continueWithProvider}
    />

    <span class="mb-4 mt-6 block text-center text-lg font-medium uppercase">
        {$_('Or')}
    </span>

    <button
        onclick={remindLater}
        data-test="remind-later-btn"
        class="btn-border mx-auto block w-full font-medium"
    >
        {$_('Remind me later')}</button
    >

    <button
        onclick={dontAskAgain}
        data-test="dont-ask-again-btn"
        class="mx-auto mt-6 block hover:underline focus:underline"
        >{$_("Don't ask again")}</button
    >
</AuthorizeLayout>
