<script>
    import { _ } from 'svelte-i18n'
    import { slide } from 'svelte/transition'
    import { global } from '$src/state.svelte.js'
    import { notification } from '$src/stores.js'
    import { dedupe } from '$utils/common'
    import { getWallet } from '$utils/ethereum'
    import { logPlausibleEvent } from '$utils/plausible'
    import {
        getConsent,
        postLinkEth,
        postLinkEthChallenge,
    } from '$utils/api.js'
    import { SLIDE_ANIM_MS } from '$src/constants.js'
    import Dropdown from '$lib/Dropdown.svelte'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import EthereumProgressModal from '$lib/modal/EthereumProgressModal.svelte'

    let {
        selected = $bindable(''),
        expanded = $bindable(false),
        handleDropdown = () => {},
    } = $props()

    let addEthereumDropdown = $state(false)
    let ethereumProgressModal = $state(null) //Will hold extension values
    let ethereumProgressNotifs = $state([])

    async function continueWithEthereumExtension() {
        const [address] = await window.ethereum.request({
            method: 'eth_requestAccounts',
        })
        ethereumProgressModal = 'extension'
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                text: $_('Wallet Connected ({address})', {
                    values: {
                        address: trimEthAddress(address),
                    },
                }),
                type: 'success',
                status: $_('Waiting to sign'),
            },
        ]
        continueEthExtensionSigning(address)
    }

    async function continueEthExtensionSigning(address) {
        let challenge, signature

        const res = await postLinkEth(address)
        logPlausibleEvent({
            u: `/start/link/ethereum/extension/${getWallet().slug}`,
            n: 'action',
        })
        challenge = res.challenge
        ethereumProgressNotifs = [
            ...ethereumProgressNotifs,
            {
                status: $_('Waiting to sign'),
            },
        ]

        try {
            signature = await window.ethereum.request({
                method: 'personal_sign',
                params: [address, challenge],
            })
            ethereumProgressNotifs = [
                ...ethereumProgressNotifs,
                {
                    text: $_(`Message signed`),
                    type: 'success',
                    status: $_('Linking wallet'),
                },
            ]
        } catch (err) {
            console.info(err)
            if (err.code === 4001) {
                notification.show(
                    $_(`You've rejected the sign request`),
                    'error',
                )
            } else {
                notification.show($_(`Something went wrong`), 'error')
            }
            ethereumProgressModal = null
            ethereumProgressNotifs = []
            return
        }

        const body = {
            signature,
            address,
            icon: getWallet().icon,
            name: getWallet().name,
        }

        await postLinkEthChallenge(body)
        logPlausibleEvent({
            u: `/link/ethereum/extension/${getWallet().slug}`,
            n: 'action',
        })
        notification.show(
            $_('{provider} {label} has been added', {
                values: {
                    provider: body.name,
                    label: trimEthAddress(address),
                },
            }),
            'success',
        )
        global.data = await getConsent()
        if (!global.data.merge) {
            selected = global.data.release?.ethereums?.[0]
            expanded = false
            if (addEthereumDropdown) {
                addEthereumDropdown = false
            }
        }

        ethereumProgressModal = null
        ethereumProgressNotifs = []
    }

    const trimEthAddress = (addr) => {
        return addr.slice(0, 6) + '...' + addr.slice(38)
    }
</script>

{#if ethereumProgressModal && ethereumProgressNotifs.length}
    <EthereumProgressModal
        notifications={ethereumProgressNotifs}
        cancel={() => {
            ethereumProgressNotifs = []
            ethereumProgressModal = null
        }}
        ok={(e) => {
            if (ethereumProgressModal === 'extension') {
                continueEthExtensionSigning(e)
            }
        }}
    />
{/if}

<div data-test="ethereum-container" class="space-y-1">
    <label for="eth-address" class="text-sm font-medium">
        {$_('Ethereum Address')}
    </label>
    {#if global.data?.release?.update_scope}
        <div class="box-shadow mt-2 space-y-2 rounded-md p-2">
            <div data-test="eth-container" class="relative flex items-center">
                <div class="w-full">
                    <ul class="mt-1 flex flex-col items-start space-y-2">
                        {#each dedupe(global.data?.release?.ethereums, 'address') as ethObj, index (ethObj.address)}
                            <li
                                data-test="eth-{index}"
                                class="flex w-full items-center"
                            >
                                <input
                                    id="eth-{ethObj.address}"
                                    name="ethereum"
                                    type="radio"
                                    bind:group={selected}
                                    value={ethObj}
                                    class="form-radio text-charcoal h-4 w-4 rounded-full dark:text-[#808080]"
                                />
                                <label
                                    for="eth-{ethObj.address}"
                                    class="ml-2.5 inline-flex w-full items-center truncate"
                                >
                                    <div
                                        class="flex w-full items-center justify-between"
                                    >
                                        <div class="flex items-center gap-x-2">
                                            <img
                                                src={selected?.wallet?.icon}
                                                class="w-4.5 max-h-[18px]"
                                                alt={selected?.wallet?.name}
                                            />
                                            <span aria-hidden="true"
                                                >{selected?.wallet?.name} ({selected
                                                    ?.wallet?.display})</span
                                            >
                                        </div>
                                    </div>
                                </label>
                            </li>
                        {/each}
                    </ul>
                </div>
            </div>

            <div class="relative">
                <Dropdown
                    hasBackground={false}
                    dataTest="add-provider-btn"
                    ariaLabel="Add another email"
                    expanded={addEthereumDropdown}
                    onclick={() => (addEthereumDropdown = !addEthereumDropdown)}
                >
                    <div
                        class="flex h-12 items-center px-4 text-left"
                        aria-hidden="true"
                    >
                        Add another wallet
                    </div>

                    {#if addEthereumDropdown}
                        <ul
                            class="space-y-2 p-2"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                        >
                            {#if window.ethereum?.isMetaMask}
                                <LoginProvider
                                    onclick={continueWithEthereumExtension}
                                    provider={{
                                        display: 'MetaMask',
                                        slug: 'metamask',
                                    }}
                                    prefix="Get from"
                                />
                            {:else if window.ethereum}
                                <LoginProvider
                                    onclick={continueWithEthereumExtension}
                                    provider={{
                                        display: 'Ethereum',
                                        slug: 'ethereum',
                                    }}
                                    prefix="Get from"
                                />
                            {/if}
                        </ul>
                    {/if}
                </Dropdown>
            </div>
        </div>
    {:else}
        <Dropdown
            hasBackground={false}
            id="eth-address"
            {expanded}
            ariaLabel={`${selected?.wallet?.name} (${selected?.wallet?.display})`}
            onclick={() => handleDropdown('ethAddress')}
        >
            <div class="flex h-12 w-full items-center justify-between px-2">
                <div class="flex items-center gap-x-3">
                    <img
                        src={selected?.wallet?.icon}
                        class="w-4.5 max-h-[18px]"
                        alt={selected?.wallet?.name}
                    />
                    <span aria-hidden="true"
                        >{selected?.wallet?.name} ({selected?.wallet
                            ?.display})</span
                    >
                </div>
            </div>

            {#if expanded}
                {@const ethereums = Array.isArray(
                    global.data?.release?.ethereums,
                )
                    ? dedupe(global.data.release.ethereums, 'address').filter(
                          (i) => i.address !== selected.address,
                      )
                    : []}
                <div
                    class="px-indent flex w-full flex-col rounded-md text-left"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <ul
                        class="mt-0.5 {ethereums.length
                            ? 'border-y'
                            : 'border-t'} divide-y divide-[#808080] border-[#808080]"
                    >
                        {#each ethereums as ethObj (ethObj.address)}
                            <li>
                                <button
                                    onclick={() => {
                                        selected = ethObj
                                        expanded = false
                                    }}
                                    class="flex h-12 w-full flex-shrink-0 items-center justify-start"
                                >
                                    <div class="flex items-center gap-x-3">
                                        <img
                                            src={ethObj?.wallet?.icon}
                                            class="w-4.5 max-h-[18px]"
                                            alt={ethObj?.wallet?.display}
                                        />
                                        <span
                                            >{ethObj?.wallet?.name} ({ethObj
                                                ?.wallet?.display})</span
                                        >
                                    </div>
                                </button>
                            </li>
                        {/each}
                    </ul>

                    <div class="my-2 space-y-2">
                        {#if window.ethereum?.isMetaMask}
                            <LoginProvider
                                onclick={continueWithEthereumExtension}
                                provider={{
                                    display: 'MetaMask',
                                    slug: 'metamask',
                                }}
                                prefix="Get from"
                            />
                        {:else if window.ethereum}
                            <LoginProvider
                                onclick={continueWithEthereumExtension}
                                provider={{
                                    display: 'Ethereum',
                                    slug: 'ethereum',
                                }}
                                prefix="Get from"
                            />
                        {/if}
                    </div>
                </div>
            {/if}
        </Dropdown>
    {/if}
</div>

<style>
    @media (prefers-color-scheme: dark) {
        .box-shadow {
            box-shadow: 0 0 0 1px #808080;
        }
    }

    @media (prefers-color-scheme: light) {
        .box-shadow {
            box-shadow: 0 0 0 1px #303030;
        }
    }

    ul li button:hover,
    ul li button:focus {
        box-shadow:
            0px -2px 0px rgb(128, 128, 128),
            0px 2px 0px rgb(128, 128, 128);
    }
</style>
